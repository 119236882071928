<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
		<div class="Wrap">
			
			
			<div class="UrHere" style="padding-top: 10px;">
				用户帮助中心:
			</div>
			
			<div class="Help">
				
				<div class="Cates">
					<div v-for="(cate,cateI) in HelpCates" :key="cateI">
						<h4>{{cate.Name}}</h4>
						<ul>
							<template v-for="(help,helpI) in HelpList">
								<li :class="CurrentHelp.Id == help.Id ? 'On':''" @click="SelectHelp(help)" v-if="help.CategoryId == cate.Id" :key="helpI">{{help.Title}}</li>
							</template>
						</ul>
					</div>
				</div>
				
				<div class="Content">
					
					<div class="Desc">
						<h1>{{CurrentHelp.Title}}</h1>
						<div class="Detail">
							<div v-html="CurrentHelp.Detail">
							</div>
						</div>
					</div>
					
					<div class="Guide">
						<p>没有得到有效信息？</p>
						<li>
							<span @click="$Jump('/about/contract')">
								<i class="el-icon-headset"></i>
								点此联系客服</span>
						</li>
						<li>
							<span @click="$Jump('/my/workorder/list')">
								<i class="el-icon-edit-outline"></i>
								点此发起工单</span>
						</li>
					</div>
					
				</div>
				
			</div>
			
		</div>
			
		<Footer></Footer>
		
	</div>
	
</template>

<script>
	import {  Checkbox,Radio,Select,Option,Upload } from 'element-ui';
	export default {
	  name: 'HelpIndex',
	  props: {
	    msg: String
	  },
	  data() {
	      return {
			  CurrentKey:'',
			  About:{
				  Id:'',
				  Title:'',
				  Detail:''
			  },
			  HelpCates:[],//帮助分类
			  HelpList:[],//帮助列表
			  CurrentHelp:{
				  Id:'',
				  Title:'',
				  Detail:''
			  },//当前资讯
			  UriId:'',
	      }
	    },
	  components: {
		  "el-radio":Radio,
		  "el-select":Select,
		  "el-option":Option,
		  "el-upload":Upload,
		  "el-checkbox":Checkbox
	  },
	  computed:{
	  },
	  created() {
		  this.GetHelpCates()
		  this.GetHelpList()
		  if(this.$route.query.id){
			  this.UriId = this.$route.query.id
		  }
	  },
	  methods:{
		  SelectHelp(_v){
			  this.CurrentHelp = _v
			  this.$Jump('/help/index?id='+_v.Id)
		  },
		  GetHelpCates(){

		  			  let data = {
		  					Service:'Help',
		  					Class: 'HelpCategory',
		  					Action: 'List',
							Status:50,
							Page:1,
							PageSize:20,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  				
		  					if(res.ErrorId != 0){
		  						this.$message({ message: res.Msg, type: 'error'});
		  						return
		  					}
							if(res.Data.HelpCategoryList == undefined || res.Data.HelpCategoryList == null){
								return
							}
		  			  		this.HelpCates = res.Data.HelpCategoryList
		  			  })
		  },
		  GetHelpList(){
		  
		  			  let data = {
		  					Service:'Help',
		  					Class: 'Help',
		  					Action: 'List',
							Status:50,
							Page:1,
							PageSize:200,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  				
		  					if(res.ErrorId != 0){
		  						this.$message({ message: res.Msg, type: 'error'});
		  						return
		  					}
		  							if(res.Data.HelpList == undefined || res.Data.HelpList == null || res.Data.HelpList.length == 0){
		  								return
		  							}
		  			  		this.HelpList = res.Data.HelpList
							if(this.UriId == ''){
								this.CurrentHelp = this.HelpList[0]
							}else{
								for(var i=0;i<this.HelpList.length;i++){
									if(this.UriId == this.HelpList[i].Id){
										this.CurrentHelp = this.HelpList[i]
									}
								}
							}
							
		  			  })
		  },
	  }
	}
</script>

<style scoped>
.Help{
	display: flex;
	width: 100%;
	margin-top: 10px;
}
.Help .Cates{
	width: 260px;
	background-color: #FFFFFF;
	padding-top: 20px;
	padding-bottom: 20px;
}
.Help .Cates h4{
	margin: 0px;
	padding: 0px 0px 0px 20px;
}
.Help .Cates div{
	padding: 10px;
}
.Help .Cates div ul{
	margin: 0px;
	padding: 0px;
}
.Help .Cates div ul li{
	cursor: pointer;
	padding: 3px 10px 3px 40px;
}
.Help .Cates div ul li:hover,.Help .Cates div ul li.On{
	color: rgba(23,134,65,1);
}

.Help .Content{
	flex: 1;
	margin-left: 20px;
	padding: 20px;
	background-color: #FFFFFF;
	display: flex;
}
.Help .Content .Desc{
	width: 800px;
}
.Help .Content .Desc .Detail{
}
.Help .Content .Guide{
	border-left: 1px solid rgba(0,0,0,0.04);
}
.Help .Content .Guide p{
	padding-left: 10px;
	color: rgba(0,0,0,0.4);
}
.Help .Content .Guide li{
	margin-top: 10px;
	padding-left: 10px;
}
.Help .Content .Guide li span{
	cursor: pointer;
}
.Help .Content .Guide li span:hover{
	color: rgba(23,134,65,1);
}
</style>
